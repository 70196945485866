/* Yeon Sung 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
/* Jua 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

.CardExplain{}
.card-category-contain h1{
  font-size: 1.75rem;
  font-weight: 900;
  margin: 0rem;
  padding: 0rem;
  text-wrap: nowrap;
  z-index: 1;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.card-category-contain h2{
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0rem;
  padding: 0rem;
  text-wrap: nowrap;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.card-category-contain h3{
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0rem;
  padding: 0rem;
  text-wrap: nowrap;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.card-category-contain {
  position: fixed;
  left: calc(100vw * 0.33 * -1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  border-collapse: collapse;
  box-sizing: border-box;
  background: #ffffff;
  transition: opacity 20s, left 0.1s;
  /* height: calc(100vh - 145.13px); */
  overflow:scroll;
  width: calc(100vw * 0.33);
  opacity: 0;
  font-family: "Yeon Sung", system-ui;
  font-weight: 400;
  font-style: normal;
}

.card-category-active {
  left: 0px;
  opacity: 1;
  transition: opacity 0.2s, left 0.5s;
}
.card-category-closeTag{
  display: none;
  width: 100%;
  text-align: center;
  background: #F9F871;
  border-top: #000000 1px solid;
}
.card-category-closeTag:hover{
  background: linear-gradient(to right, #6BBAA3, #CDFF06);
  cursor: pointer;
}
.card-category-closeTag-active{
  display: block;
}
.category-certi-div{
  width: 100%;
}
.category-certi-div-deactivate{
  display: none;
}
.card-certi1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  box-sizing: border-box;
  border-collapse: collapse;
}
.card-certi2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: none;
}
.card-certi2 > h1, .card-certi2 > h2, .card-certi2 > h3{
  width: 100%;
  text-align: center;
}

.card-certi3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: none;

}

.card-certi1:hover{
  background: linear-gradient(to right, #C7FCEB, #FFC65A);
  cursor: pointer;
}
.card-certi2:hover{
  background: linear-gradient(to right, #F3ECFF, #007FFD);
  cursor: pointer;
}
.card-certi3:hover{
  background: linear-gradient(to right, #F9F871, #FF9071);
  cursor: pointer;
}

.card-certi1:focus{
  background: linear-gradient(to right, #C7FCEB, #FFC65A);
  cursor: pointer;
}
.card-certi2:focus{
  background: linear-gradient(to right, #F3ECFF, #007FFD);
  cursor: pointer;
}
.card-certi3:focus{
  background: linear-gradient(to right, #F9F871, #FF9071);
  cursor: pointer;
}

.card-certi1:active{
  background: linear-gradient(to left, #C7FCEB, #FFC65A);
  cursor: pointer;
}
.card-certi2:active{
  background: linear-gradient(to left, #F3ECFF, #007FFD);
  cursor: pointer;
}
.card-certi3:active{
  background: linear-gradient(to left, #F9F871, #FF9071);
  cursor: pointer;
}


.CgContain{
  width: 100%;
}

.card-explain-body{
  width: 100%;
  height: 100%;
}
.card-explain-cateogry-open{
  height: calc(2rem + 2vmin);
  width: calc(2rem + 2vmin);
  position: fixed;
  top: calc(50vh - 1rem - 1vmin);
  left: 0rem;
  opacity: 1;
  z-index: 1;
}
.card-explain-cateogry-open-none{
  transition-duration: 0s;
  opacity: 0;
  z-index: -1;
}
.card-explain-cateogry-open-icon{
  height: 100%;
  width: 100%;
}

.test-active{
  width: 200px;
  height: 200px;
  border: #000000 1px solid;
}


/*  */
/* 태블릿 이상 노트북 이하 */
@media all and (min-width:480px) and (max-width:1023px) {
  .CardExplain{
  }
  .CardExplain  h1, h2, h3{
  }
  .card-category h1{
    font-size: px;
  }
  .card-category h2{
  }
  .card-category h3{
  }
  .card-category{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    box-sizing: border-box;
    padding: 0rem 1rem; */
  }
  .card-category-contain{
    height: calc(100vh - 115.41px);
    width: 18.75rem;
    left: calc(18.75rem * -1);
  }
  .card-category-active {
    left: 0px;
    /* opacity: 1; */
    /* transition: opacity 0.2s, left 0.5s; */
  }
  .card-explain-body{
  }
  .card-certi1{
  }
  .card-certi2{
  }
  .card-certi3{
  }
}

/*  */
/* 스마트폰 세로 (해상도 ~ 479px)*/ 
@media all and (max-width:479px) {
  .CardExplain{
  }
  .CardExplain  h1, h2, h3{
  }
  .card-category h1{
  }
  .card-category h2{
  }
  .card-category h3{
  }
  .card-category{
  }
  .card-category-contain{
    height: calc(100vh - 49.36px);
    width: 100vw;
    left: -100vw;
  }
  .card-category-active {
    left: 0px;
    /* opacity: 1; */
    /* transition: opacity 0.2s, left 0.5s; */
  }

  .card-explain-cateogry-open{
    height: calc(2rem + 2vmin);
    width: calc(2rem + 2vmin);
    /* position: fixed; */
    top: calc(50vh - 1rem - 1vmin);
    /* left: 0rem; */
    /* opacity: 1; */
    /* z-index: 0; */
  }
  
  .card-explain-body{
  }
  .card-certi1{
  }
  .card-certi2{
  }
  .card-certi3{
  }
}