/* Yeon Sung 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
/* Jua 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

.ReadCard2{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Yeon Sung", system-ui;
  font-weight: 400;
  font-style: normal;
}
.container{
  width: 90%;
}
.selected-div{
  display: flex;
  justify-content:space-around;
  margin-top: 1rem;
}
.cardShow{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
}
.showImg{
  width: 12vw;
}
.cardNameSize{
  font-size: 1rem;
}

.answerDiv{
  margin: 2rem 0rem 2rem 0rem;
}
.consulP{
  font-size: 1.25rem;
}
.answerP{
  font-size: 1.125rem;
  margin :  1em 0em 1em 0em
}
.saveBtn {
  margin: 4em 0em 4em 0em;
  text-align: center;
}
.saveBtn >span{
  font-size : 1.25rem;
  padding: 0.5em 2em 0.5em 2em;
  background: #FFC65A;
  border-radius: 12px;
  cursor: pointer;
  color: #000000;
}
/* 효과를 동작시간 동안 0 ~ 1까지 */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 효과를 동작시간 동안 0 ~ 1까지 */
/* @keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
} */

.loadingMessageDiv{
  text-align: center;
}
.loadingMessage{
  font-size: 1.25rem;
}
.loadingIcon{
  width: 20%;
  margin: 2em;
  animation: loading_image 2s linear infinite;
}
@keyframes loading_image {
  100% {
    transform: rotate(360deg);
  }
}

@media all and (max-width:768px) {
  .cardNameSize{
    font-size: 0.625rem;
  }
  .loadingMessage{
    font-size: 1rem;
  }
  .consulP{
    font-size: 1.125rem;
  }
  .answerP{
    font-size: 1rem;
  }
  .loadingIcon{
    width: 25%;
  }
  .saveBtn {}
  .saveBtn >span{
    font-size : 1.125rem;
  }
}

@media all and (max-width:480px) {
  .selected-div{
    flex-wrap: wrap;
  }
  .showImg{
    width: 25vw;
  }
  .loadingMessage{
    font-size: 0.75rem
  }
  .loadingMessageDiv{
    margin-top: 0rem;
  }
  .consulP{
    font-size: 1rem;
  }
  .answerP{
    font-size: 0.875rem;
  }
  
  .loadingIcon{
    width: 40%;
  }
  .saveBtn {}
  .saveBtn >span{
    font-size : 1rem;
  }
}