.Home {
  text-align: center;
}
.Home h1{
  margin: 0rem;
  font-size: calc(3rem + 1vw);
}
.Home h2{
  margin: 0rem;
  font-size: calc(2.25rem + 1vw);
}

.HomeImage1 {
  position: relative;
  width: 100vw;
  /* height: calc(100vw * 11 / 24); */
  height: 100vh;
}
.HomeImage1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/images/homeBg1.png');
  background-size: cover;
  background-position: center;
  opacity: 0.33;
  z-index: -1;
}
.HomeText1-contain{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.HomeText1{
  margin: 0rem;
  white-space: nowrap;
  overflow: hidden;
  width: 0%;
  opacity: 0;
  /* animation: text-appears 3s steps(100, end) forwards, fadeIn 3s forwards; */
}

.text-appear-ani{
  animation: text-appears 2.5s steps(100, end) forwards, fadeIn 2s forwards;
}

.text-appear-ani2{
  animation: fadeIn 1s forwards;
}

@keyframes text-appears{
  from{ width: 0%}
  to { width: 100%}
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.sticky-div{
  height: 750vh;
  width: 100vw;
}
.HomeImage2{
  position: sticky;
  top:0;
  background-image: url('../../assets/images/homeBg2.png');
  background-size: cover;
  opacity: 0.33;
  height: 100vh;
  width: 100vw;
  z-index: -1;

}
.HomeText2-contain{
  margin-top: -100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.HomeText2{
  margin: 0rem;
  white-space: nowrap;
  overflow: hidden;
  /* width: 0%; */
  /* opacity: 0; */
  /* animation: text-appears 3s steps(100, end) forwards, fadeIn 7s forwards; */
}

.kinds-contain{
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content:space-around;
  align-items: center;
}
.HomeText3-contain{}
.HomeText3{}
.fortune-ex-contain{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  opacity: 1;
}

.fortune-ex-contain-opacityactive{
  opacity: 0;
}

.fortune-ex{
  margin: 0rem 3rem;
  cursor: default;
}
.fortune-ex-clickable{
  cursor: pointer;
}
.fortune-ex > p{
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.fortune-ex-fig{
  height: 6.25rem;
  width: 6.25rem;
}
.HomeText4-contain{}
.HomeText4{}
.img-division{}
.HomeImage3 {
  position: relative;
  width: 100vw;
  /* height: calc(100vw * 11 / 24); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.HomeImage3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/images/homeBg3.png');
  background-size: cover;
  background-position: center;
  opacity: 0.33;
  z-index: -1;
}
.HomeText5-contain{}
.HomeText5{}
.HomeText6-contain{}
.HomeText6{}

.TMT-logo{
  /* height: calc(500/20px); */
  /* width: calc(3773/20px); */
  /* height: 6.25rem; */
  /* width: 47.1625rem; */
  /* height: calc(500/20px); */
  /* width: calc(3773/20px); */
  height: calc(5.208125rem + 5vw);
  width: calc(39.301875rem + 30vw);
  cursor: pointer;
}
.TMT-logo:hover{
  animation: leanTitle 2s infinite;
}
@keyframes leanTitle {
  0%, 100% {
    transform: rotate(-1deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  75% {
    transform: rotate(1deg);
  }
}
/*  */
/* 태블릿 이상 노트북 이하 */
@media all and (min-width:480px) and (max-width:1023px) {
  .Home {}
  .Home h1{
    margin: 0rem;
    font-size: calc(0.375rem + 3vw);
  }
  .Home h2{
    margin: 0rem;
    font-size: calc(0.25rem + 3vw);
  }
  
  .HomeImage1 {
  }
  .HomeImage1::before {

  }
  .HomeText1-contain{

  }
  .HomeText1{

  }
  .HomeImage2{
    
  }
  .HomeText2-contain{
    
  }
  .HomeText2{
    
  }
  
  .kinds-contain{
    
  }
  .HomeText3-contain{}
  .HomeText3{}
  .fortune-ex-contain{
    
  }
  .fortune-ex{
    margin: 1rem 3rem;
  }
  .fortune-ex > p{
  }
  .fortune-ex-fig{
  }
  .HomeText4-contain{}
  .HomeText4{}
  .img-division{}
  .HomeImage3{} 
  .HomeText5-contain{}
  .HomeText5{}
  .HomeText6-contain{}
  .HomeText6{}
  .TMT-logo{
    /* height: calc(500/20px); */
    /* width: calc(3773/20px); */
    height: calc(1.041875rem + 6vw);
    width: calc(7.860625rem + 42vw);
  }
}


/*  */
/* 스마트폰 세로 (해상도 ~ 479px)*/ 
@media all and (max-width:479px) {
  .Home {}
  .Home h1{
    margin: 0rem;
    font-size: calc(0.375rem + 2vw);
  }
  .Home h2{
    margin: 0rem;
    font-size: calc(0.25rem + 2vw);
  }
  
  .HomeImage1 {
    height: 100vh;
  }
  .HomeImage1::before {

  }
  .HomeText1-contain{

  }
  .HomeText1{

  }
  .HomeImage2{
    
  }
  .HomeText2-contain{
    
  }
  .HomeText2{
    
  }
  
  .kinds-contain{
    
  }
  .HomeText3-contain{}
  .HomeText3{}
  .fortune-ex-contain{
  }
  .fortune-ex{
    margin: 3rem 3rem;
  }
  .fortune-ex > p{
    font-size: calc(0.25rem + 2vw);
    margin-top: 0rem;
    margin-bottom: 0.5rem;
  }
  .fortune-ex-fig{
    height: calc(3rem + 2vw);
    width: calc(3rem + 2vw);
  }
  .HomeText4-contain{}
  .HomeText4{}
  .img-division{}
  .HomeImage3{}
  .HomeText5-contain{}
  .HomeText5{}
  .HomeText6-contain{}
  .HomeText6{}
  .TMT-logo{
    /* height: calc(500/20px); */
    /* width: calc(3773/20px); */
    height: calc(1.041875rem + 5vw)  ;
    width: calc(7.860625rem + 35vw);
  }
}