.TarotShop{
  height: 100%;
  width: 100%;
}
.kakaomap{

}

.radius_border{
  border:1px solid #919191;
  border-radius:5px;
}
.custom_zoomcontrol {
  position:absolute;
  bottom:20px;
  right:20px;
  width:36px;
  height:80px;
  overflow:hidden;
  z-index:1;
  background-color:#f5f5f5;
}
.custom_zoomcontrol span {
  display:block;
  width:36px;
  height:40px;
  text-align:center;
  cursor:pointer;
}
.custom_zoomcontrol span img {
  width:15px;
  height:15px;
  padding:12px 0;
  border:none;
}
.custom_zoomcontrol span:first-child{
  border-bottom:1px solid #bfbfbf;
}

.custom_location {
  position:absolute;
  bottom:110px;
  right:20px;
  width:36px;
  height:40px;
  overflow:hidden;
  z-index:1;
  background-color:#f5f5f5;
}
.custom_location span {
  display:block;
  width:36px;
  height:40px;
  text-align:center;
  cursor:pointer;
}
.custom_location span img {
  width:15px;
  height:15px;
  padding:12px 0;
  border:none;
}


.wrap {position: absolute;left: 0;bottom: 40px;width: 288px;height: 132px;margin-left: -144px;text-align: left;overflow: hidden;font-size: 12px;font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;line-height: 1.5;}
.wrap * {padding: 0;margin: 0;}
.wrap .info {width: 286px;height: 120px;border-radius: 5px;border-bottom: 2px solid #ccc;border-right: 1px solid #ccc;overflow: hidden;background: #fff;}
.wrap .info:nth-child(1) {border: 0;box-shadow: 0px 1px 2px #888;}
.info .title {padding: 5px 0 0 10px;height: 30px;background: #eee;border-bottom: 1px solid #ddd;font-size: 18px;font-weight: bold;}
.info .body {position: relative;overflow: hidden;}
.info .desc {position: relative;margin: 0.8125rem 0 0 0.825rem;height: 4.6875rem;}
.desc .ellipsis {overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.desc .jibun {font-size: 11px;color: #888;margin-top: -2px;}
/* .info .img {position: absolute;top: 6px;left: 5px;width: 73px;height: 71px;border: 1px solid #ddd;color: #888;overflow: hidden;} */
.info:after {content: '';position: absolute;margin-left: -12px;left: 50%;bottom: 0;width: 22px;height: 12px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}
.info .link {color: #5085BB;}

.info .close {position: absolute;top: 10px;right: 10px;color: #888;width: 17px;height: 17px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/overlay_close.png');}
.info .close:hover {cursor: pointer;}