/* Yeon Sung 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
/* Jua 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

.ReadCard1{
  display: flex;
  justify-content: center;
  font-family: "Yeon Sung", system-ui;
  font-weight: 400;
  font-style: normal;
}
.ReadCard1 h2{
  font-size: 1.5rem;
  margin: 0rem;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.RC-left{
  width: calc(1024px * 0.47);
  padding-right: calc(1024px * 0.015);
  margin-top: 2rem;
}

.CS-div{
  display: flex;
  margin: 1rem 0rem 1rem 0rem;
  align-items: center;
}

.CS-div > p{
  margin: 0rem;
  margin-right: 1em;
  font-size: 1.25rem;
}
.custom-select-large{
  width: 10rem;
  text-align: center;
}
.custom-select-large:hover{
  cursor: pointer;
}
.custom-select-vision{
  border: 1px solid black;
  border-radius: 0.25rem;
  box-sizing: border-box;
}
.custom-select-vision:hover{
  border-color: #aaa;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px 3px #CCCEFB;
  /* box-shadow: 0 0 0 3px -moz-mac-focusring; */
  color: #222;
  outline: none;
}

.custom-select-vision :focus{
  border-color: #aaa;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px 3px #CCCEFB;
  /* box-shadow: 0 0 0 3px -moz-mac-focusring; */
  color: #222;
  outline: none;
}
.custom-select-vision :active{
  border-color: #aaa;
  border-radius: 0.25rem;
  box-shadow: 0 0 1px 3px #CCCEFB;
  /* box-shadow: 0 0 0 3px -moz-mac-focusring; */
  color: #222;
  outline: none;
}

.custom-select-text{
  margin: 0px;
  padding: 0.25rem;
}
.custom-select-large-option-list{
  position: absolute;
  list-style-type: none;
  margin: 0px;
  margin-top: 0.25rem;
  padding: 0px;
  display: none;
  z-index: 1;
  background: #ffffff;
  width: 10rem;
  border: 1px solid black;
  border-radius: 0.25rem;
  box-sizing: border-box;
}
/* .custom-select-large-option-list :nth-child(1){
  border-top: 1px solid black;
} */
.CSLOL-active{
  display: block;
}

.custom-select-large-option-list :hover{
  background: #F3ECFF;
}
.custom-select-large-option{
  margin: 0px;
  padding: 0px;
  /* border: 1px solid black; */
  border-top: 0px;
  box-sizing: border-box;
  border-collapse: collapse;
}

.consultation-div > p{
  font-size: 1.25rem;
}

.input-consultation{
  width: 100%;
  height: 3rem;
  resize: none;
}

.RC-left-explain{
  margin-top: 2rem;
}
.pRedBlink {
  animation: blink 2s infinite;
}

@keyframes blink {
  0%, 100% {
    color: rgb(191, 63, 63);
  }
  25% {
    color: rgb(255,0,0);
  }
  50% {
    color: rgb(191, 63, 63);
  }
  75% {
    color: rgb(255,0,0);
  }
}

.RC-right{
  width: calc(1024px * 0.47);
  padding-left: calc(1024px * 0.015);
  margin-top: 2rem;
}
.drawcard-div{
  width: 100%;
  background: #F3ECFF;
  text-align: center;
  padding: 0.5rem 0rem 0.5rem 0rem;
}
.drawcard-img-div{
  width: 12%;
  display: inline-block;
  text-align: center;
  margin: 0.625rem;
}
.drawcard-img{
  /* width: 12%; */
  width: 100%;
  /* margin: 0.625rem; */
  cursor: pointer;
  transition: transform 0.3s;
  transform: perspective(800px) rotateY(0deg);
}
.drawcard-img:hover:not(.drawcard-img-selected){
  opacity: 0.8;
  /* transform: perspective(800px) rotateY(180deg); */
}
.drawcard-img:active:not(.drawcard-img-selected){
  opacity: 0.6;
  /* transform: perspective(800px) rotateY(180deg); */
}
.drawcard-img-selected{
  opacity:0.4;
}
 /* Default duration is 0.3s */
.drawcard-img-rotate{
  transform: perspective(800px) rotateY(90deg);
  transition: transform var(--rotate-duration, 0.3s);
}

.RC-right-btn-div{
  display: flex;
  justify-content:space-around;
  margin: 1em 0em 2em 0em;
}
.RC-right-btn{
  width: calc(1024px * 0.47 * 0.33);
  padding: 1em;
  border-radius: 0.25em;
  cursor: pointer;
  background: #b3b3b3;
}
.RC-right-btn > p{
  margin: 0px;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
}

.RC-right-btn-mix{
  background: #FFC65A;
}
.RC-right-btn-mix:active{
  opacity: 0.8;
}

.RC-right-btn-read{
  background: #6BBAA3;
}
.RC-right-btn-read:active{
  opacity: 0.8;
}

@media all and (max-width:1023px) {
  .ReadCard1{
    flex-direction: column;
    align-items: center;
  }
  .RC-left{
    /* width: calc(1024px * 0.47); */
    width: 80vw;
    padding-right: 0px;
    margin-top: 2rem;
  }
  .RC-right{
    /* width: calc(1024px * 0.47); */
    width: 80vw;
    padding-left: 0px;
    margin-top: 1em;
  }
  .drawcard-div{
    width: 100%;
    background: #F3ECFF;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem;
  }
  .drawcard-img-div{
    width: 8%;
    display: inline-block;
    text-align: center;
    margin: 0.625rem;
  }
  .RC-left-select > h2{
    font-size: 1.375rem
  }
  .CS-div > p{
    margin: 0rem;
    margin-right: 1em;
    font-size: 1rem;
  }
  .consultation-div > p{
    font-size: 1rem;
  }
  .RC-left-explain > h2{
    font-size: 1.375rem
  }
  .RC-left-explain > p{
    font-size: 0.875rem
  }
}
@media all and (max-width:768px) {
  .drawcard-img-div{
    width: 10%;
    display: inline-block;
    text-align: center;
    margin: 0.625rem;
  }
}
@media all and (max-width:480px) {
  .RC-left{
    /* width: calc(1024px * 0.47); */
    width: 85vw;
    padding-right: 0px;
  }
  .RC-right{
    /* width: calc(1024px * 0.47); */
    width: 85vw;
    padding-left: 0px;
    margin-top: 1em;
  }
  .drawcard-div{
    width: 100%;
    background: #F3ECFF;
    text-align: center;
    padding: 0rem 0rem 0rem 0rem;
  }
  .drawcard-img-div{
    width: 10%;
    display: inline-block;
    text-align: center;
    margin: 0.625rem;
  }
  
  .RC-left-select > h2{
    font-size: 1.25rem
  }
  .CS-div > p{
    margin: 0rem;
    margin-right: 1em;
    font-size: 0.875rem;
  }
  .consultation-div > p{
    font-size: 0.875rem;
  }
  .RC-left-explain > h2{
    font-size: 1.25rem
  }
  .RC-left-explain > p{
    font-size: 0.75rem
  }
  
  .RC-right-btn{
    width: 30%;
    padding: 1em;
    border-radius: 0.25em;
    cursor: pointer;
  }
  .RC-right-btn > p{
    margin: 0px;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 700;
  }
  
}