.NotFound{
  width: 100vw;
  height: 100vh;
}
.notFoundContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.notFoundContainer >div{
  margin: 2rem;
}
.notFoundImg{
  width: 180px;
  height: 180px;
}
.button404{
  margin-top: 1rem;
  font-size: 1.25rem;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 6px;
  padding: 0.5em;
  width: 130px;
}
.button404maring{
  margin-right: 1em;
}