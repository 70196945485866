/* Yeon Sung 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');
/* Jua 폰트 */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

/* 데스크탑 */
.nav-logo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.nav-logo1{
  display: flex;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.nav-logo1 > a{
  display: flex;
  align-items: center;
}
.main-logo{
  width: 30rem;
  padding: 1rem 0.5rem;
}
.nav-logo2{
  font-size: 1.25rem;
  position: absolute;
  right: 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.nav-logo2 > a{
  text-decoration: none;
  color: #000000;
}
.profileImg{
  height: 50px;
  border-radius: 50%;
}
.nickname{
  margin-left: 1rem;
  font-size: 1.25rem;
}

.nav-toggleBtn{
  font-size: 1.25rem;
  position: absolute;
  right: 1rem;
  display: none;
}


.nav-bar{
  background-color: rgba(100, 105, 243, 0.33);
  padding: 0rem;
  display: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  vertical-align: middle;
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.nav-bar-active{
  display: flex;
}

.nav-bar > a{
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  font-size: 1.375rem;
  /* font-weight: 700; */
  opacity: 100%;
  text-decoration: none;
  color: #000000;
  
}
.nav-bar > a:hover{
  background: rgba(255, 99, 161, 0.33);
  font-size: 1.625rem;
  transition: 0.3s;
}
/* .nav-bar > a:focus{
  background: rgba(255, 99, 161, 0.33);
} */
.nav-bar > a:active{
  background-color: rgba(255, 99, 161, 1);
}

.nav-bar .nav-bar-login{
  display: none;
}

/*  */
/* 태블릿 이상 노트북 이하 */
@media all and (min-width:480px) and (max-width:1023px) { 
  .main-logo{
    width: 20rem;
  }
  
  .nav-logo2{
    font-size: 1rem;
    right: 1rem;
  }

  .nav-bar{
    
  }
  .nav-bar > a{
    font-size: 1.125rem;
    height: 2.5rem;
  }
  .nav-bar > a:hover{
    font-size: 1.375rem;
  }
  .profileImg{
    height: 30px;
    border-radius: 50%;
  }
  .nickname{
    margin-left: 0.5rem;
    font-size: 0.875rem;
  }
} 

/*  */
/* 스마트폰 세로 (해상도 ~ 479px)*/ 
@media all and (max-width:479px) {
  .main-logo{
    width: 8rem;
  }
  .nav-logo2{
    display: none;
  }

  .nav-toggleBtn{
    display: block;
  }
  .nav-toggleBtn:hover{
    cursor: pointer;
  }

  .nav-bar{
    background-color:transparent;
    width: 100%;
    flex-direction: column;
    padding: 0rem;
    position: absolute;
    z-index: 2;
    display: flex;
    opacity: 0;
    transform: translateY(-120%);
  }
  .nav-bar-active{
    opacity: 1;
    transform: translateY(0%);
  }
  .nav-bar > a{
    padding: 0.5rem 0rem;
    background: #CCCEFB;
    height: 2rem;
    font-size: 1.5rem;
    text-align: center;
    
  }
  .nav-bar > a:hover{
    background-color: #F4CCFB;
    padding: 0.5rem 0rem;
    font-size: 1.75rem;
  }

  .nav-bar .nav-bar-login{
    display: block;
  }
  

  .nav-bar1{}
  .nav-bar2{
    transform: translateY(-100%);
  }
  .nav-bar3{
    transform: translateY(-200%);
  }
  .nav-bar4{
    transform: translateY(-300%);
  }
  .nav-bar5{
    transform: translateY(-400%);
  }
  .nav-bar-link-active{
    /* transition: 0.35s; */
    transition: 0.2s;
    transform: translateY(0%);
  }
}