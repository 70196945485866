.MyPage{
  display:flex;
  justify-content: center;
}
.myPageContainer{
  width: 700px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table td {
  padding: 8px;
  text-align: left;
}

.styled-table .first-column {
  width: 20%;
  font-weight: bold;
  text-align: center;
  background: #f3dbff;
}
/* .styled-table>tbody>tr>td:nth-child(1){
  width: 20%;
  font-weight: bold;
  text-align: center;
  background: #f3dbff;
} */
/* .first-column{
  width: 20%;
  font-weight: bold;
  text-align: center;
  background: #f3dbff;
} */
.logoutP{
  display: flex;
  justify-content: space-between;
}
/* .logoutP>span:hover{
  color: red;
} */
/* .logoutP > span:first-child:hover {
  color: red;
} */
.logoutP span{
  cursor: pointer;
}
.logoutP > span:nth-child(1):hover {
  color: #FFC65A;
}
.logoutP > span:nth-child(2):hover {
  color: red;
}
.logoutP>span:active{
  color: #007FFD !important;
}